import React from "react"

export default function arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-24"
      viewBox="0 0 99 96"
    >
      <path fill="#fff" d="M91.3 4H6v2h83.7L6.8 89l1.4 1.7L91 7.8V91h2V4z" />
    </svg>
  )
}
